/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Auth home Loading card skeleton
 *
 * @file LoadingCard.jsxs
 * @author Samuel S Gladson
 */

import React from 'react';
import './loader.scss';
import Footer from '../Footer';
import CommonUtils from '../../../common/utils/CommonUtils';
import {
  ChannelsSliderLoader,
  CourseSectionLoader,
  HeaderLoader,
  HeroGenericShimmer
} from './ComponentLoader';
import FooterIllustration from '../FooterIllustration';
import Container from '../../../common/components/Container';

const LoadingCard = () => {
  const footerMenuList = CommonUtils.footerMenuList();

  return (
    <div
      style={{ background: '#020917', height: '100%' }}
      className="one-reader-maincontainer"
    >
      <HeaderLoader />
      <div className="one-reader-content-wrapper">
        <Container
          enableGutters
          enableTopAndBottomPadding
        >
          <CourseSectionLoader />
        </Container>
        <Container
          enableGutters
          enableTopAndBottomPadding
        >
          <HeroGenericShimmer withoutWrapper />
        </Container>
        <Container
          enableGutters
          enableTopAndBottomPadding
        >
          <ChannelsSliderLoader />
        </Container>
      </div>
      <FooterIllustration />
      <Footer {...footerMenuList} />
    </div>
  );
};

export default LoadingCard;
