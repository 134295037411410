/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TOC mapper utility for ET1 PDF
 *
 * @file TocMapperPdf.js
 * @author Vignesh Selvam
 */

import Framework from '@greenville/framework';

import HotspotUtils from './HotspotUtils';
import CommonUtils from './CommonUtils';

import {
  ASSESSMENT,
  ASSET,
  CHAPTER,
  CUSTOM,
  GLOSSARY,
  PDF_CONSTANTS,
  SLATE,
  STANDARDS
} from '../constants';

export default class TocMapperPdf {
  /**
   * Method for constructing tree structured TOC
   *
   * @param {Object} tocData
   * @param {Array} pagePlaylist
   * @param {string} userRole
   * @param {Boolean} isAudioBook
   */
  static constructTree = (tocData, pagePlaylist, userRole, isAudioBook, asset) => {
    if (tocData.type === GLOSSARY) {
      return false;
    }
    const {
      FLV,
      SWF,
      IPADAPP,
      JAZZASSET,
      GLOSSARY_TERM
    } = PDF_CONSTANTS.LINK_TYPE;
    const restrictedLinkTypes = [FLV, SWF, IPADAPP, JAZZASSET, GLOSSARY_TERM];
    const customAsset = [ASSESSMENT, ASSET, CUSTOM, STANDARDS];
    const tocNode = {};

    tocNode.title = tocData.title;
    tocNode.children = [];
    tocNode.linkUri = tocData.uri || '';
    tocNode.uri = tocData.uri;
    tocNode.actualPageUri = tocData.uri;
    tocNode.pageOrder = tocData.playOrder;
    tocNode.pageNumber = tocData.pageno;
    tocNode.type = CHAPTER;
    const pageInfo = pagePlaylist.find(page => (page.pageno === tocData.pageno));
    let tocId = pageInfo && pageInfo.playOrder;
    if (customAsset.includes(tocData.type)) {
      tocId = (tocData.id || '').toString();
      tocNode.type = tocData.type;
    }
    tocNode.id = tocId;
    tocNode.linkTypeId = tocData.linkTypeID || '';
    tocNode.regionTypeId = tocData.regionTypeID || '';
    tocNode.role = tocData.role;
    if (pageInfo && pageInfo.audio) {
      tocNode.audio = CommonUtils.setPdfAudioUrl(pageInfo.audio, asset);
    }
    if (tocData.children) {
      tocData.children.forEach((tocDataChildren, key) => {
         if (tocDataChildren.type !== SLATE) {
          const childNode = this.constructTree(tocDataChildren, pagePlaylist, userRole, isAudioBook, asset);

          if (childNode) {
            if (customAsset.includes(childNode.type)) {
              if (HotspotUtils.isValidHotspot(childNode, null, userRole, restrictedLinkTypes, true)) {
                tocNode.children.push(childNode);
              }
            } else {
              tocNode.children.push(childNode);
            }
          }
        } else {
          const childNode = this.identifyPages(tocDataChildren, pagePlaylist, tocData.children[key + 1], asset);

          if (childNode.inBetweenPage && childNode.inBetweenPage.length) {
            childNode.inBetweenPage.forEach((page) => {
              tocNode.children.push(page);
            });
          } else if (childNode) {
            tocNode.children.push(childNode);
          }
        }
      });
    }

    return tocNode;
  };

  /**
   * Method for traverse toc
   *
   * @param {Array} tocChildren
   * @param {string} bundleId
   * @param {string} userRole
   * @param {Boolean} isServerRender
   * @param {Boolean} isAudioBook
   */
  static tocTraverse = (tocChildren, bundleId, userRole, isServerRender, isAudioBook) => {
    const tocLevelChildList = {};
    const pagePlaylist = bundleId ? tocChildren.children : tocChildren.slates;

    if (isServerRender) {
      const spdfTocData = this.spdfToc(tocChildren, pagePlaylist, userRole, isAudioBook, tocChildren);

      return spdfTocData;
    }
    tocLevelChildList.id = tocChildren.id;
    tocLevelChildList.title = tocChildren.title;
    tocLevelChildList.type = tocChildren.type;
    tocLevelChildList.children = [];
    tocLevelChildList.ProductId = tocChildren.productId;
    (tocChildren.children).forEach((tocData) => {
      const childList = this.constructTree(tocData, pagePlaylist, userRole, isAudioBook, tocChildren);
      let userHasAccess = true;

      if (childList) {
        if (childList.role) {
          userHasAccess = HotspotUtils.isValidRole(childList, userRole);
        }
        if (userHasAccess) {
          tocLevelChildList.children.push(childList);
        }
      }
    });

    return tocLevelChildList;
  };

  /**
   * Method to traverse server side toc
   *
   * @param {Array} tocChildren
   * @param {Array} pagePlaylist
   * @param {string} userRole
   * @param {Boolean} isAudioBook
   */
  static spdfToc = (tocChildren, pagePlaylist, userRole, isAudioBook, asset) => {
    const tocLevelChildList = {};

    tocLevelChildList.title = tocChildren.title;
    tocLevelChildList.type = 'Book';
    tocLevelChildList.children = [];
    tocLevelChildList.ProductId = tocChildren.productId;
    (tocChildren.children).forEach((tocData) => {
      const childList = this.constructSpdfTree(tocData, pagePlaylist, userRole, isAudioBook, asset);
      let userHasAccess = true;

      if (childList) {
        if (childList.role) {
          userHasAccess = HotspotUtils.isValidRole(childList, userRole);
        }
        if (userHasAccess) {
          tocLevelChildList.children.push(childList);
        }
      }
    });

    return tocLevelChildList;
  }

  /**
   * Method to construct tree structured TOC for SPDF
   *
   * @param {Object} tocData
   * @param {Array} pagePlaylist
   * @param {string} userRole
   * @param {Boolean} isAudioBook
   */
  static constructSpdfTree = (tocData, pagePlaylist, userRole, isAudioBook, asset) => {
    if (tocData.type === GLOSSARY) {
      return false;
    }
    const {
      FLV,
      SWF,
      IPADAPP,
      JAZZASSET,
      GLOSSARY_TERM
    } = PDF_CONSTANTS.LINK_TYPE;
    const restrictedLinkTypes = [FLV, SWF, IPADAPP, JAZZASSET, GLOSSARY_TERM];
    const customAsset = [ASSESSMENT, ASSET, CUSTOM, STANDARDS];
    let parentId;
    let playOrder;
    const tocNode = {};
    tocNode.children = [];
    let pageInfo = {};
    pagePlaylist.forEach((page) => {
      if (page.pageno === tocData.pageno) {
        parentId = page.parentid;
        playOrder = page.playOrder;
        pageInfo = page;
      }
    });
    let tocId = `${playOrder}`;

    tocNode.label = tocData.title;
    tocNode.href = tocId;
    tocNode.uri = tocId;
    tocNode.actualPageUri = tocData.uri;
    tocNode.linkUri = tocData.uri || '';
    tocNode.pageOrder = tocData.playOrder;
    tocNode.pageNumber = tocData.pageno;
    tocNode.type = CHAPTER;
    tocNode.parentId = parentId;
    if (customAsset.includes(tocData.type)) {
      tocId = (tocData.id && (tocData.id).toString()) || '';
      tocNode.type = tocData.type;
    }
    tocNode.id = tocId;
    tocNode.linkTypeId = tocData.linkTypeID || '';
    tocNode.regionTypeId = tocData.regionTypeID || '';
    tocNode.role = tocData.role;
    if (pageInfo && pageInfo.audio) {
      tocNode.audio = CommonUtils.setPdfAudioUrl(pageInfo.audio, asset);
    }
    if (tocData.children) {
      tocData.children.forEach((tocDataChildren, key) => {
        if (tocDataChildren.type !== SLATE) {
          const childNode = this.constructSpdfTree(tocDataChildren, pagePlaylist, userRole, isAudioBook, asset);

          if (childNode) {
            if (customAsset.includes(childNode.type)) {
              if (HotspotUtils.isValidHotspot(childNode, null, userRole, restrictedLinkTypes, true)) {
                tocNode.children.push(childNode);
              }
            } else {
              tocNode.children.push(childNode);
            }
          }
        } else {
          const childNode = this.identifySpdfPages(tocDataChildren, pagePlaylist, tocData.children[key + 1], asset);

          if (childNode.inBetweenPage && childNode.inBetweenPage.length) {
            childNode.inBetweenPage.forEach((page) => {
              tocNode.children.push(page);
            });
          } else if (childNode) {
            tocNode.children.push(childNode);
          }
        }
      });
    }

    return tocNode;
  };

  /**
   * Function to get in between pages of client side PDF
   *
   * @param {Object} tocData
   * @param {Array} pagePlaylist
   * @param {object} nextToc
   */

   static identifyPages = (tocData, pagePlaylist, nextToc, asset) => {
     let tocNode = {};
     const startPage = pagePlaylist.find(page => page.pageno === tocData.pageno);
     const startPageIndex = pagePlaylist.indexOf(startPage);
     const lastPage = nextToc && pagePlaylist.find(page => page.pageno === nextToc.pageno);
     const lastPageIndex = pagePlaylist.indexOf(lastPage);
     const diffIndex = lastPageIndex - startPageIndex;
     const language = Framework.getStoreRegistry().getStore('language');
     const pageTitle = language.getMessage('navigation.PAGE');

     if (nextToc && nextToc.type === SLATE && diffIndex > 1) {
       const inBetweenPages = pagePlaylist.slice(startPageIndex, lastPageIndex);
       tocNode.inBetweenPage = [];

       inBetweenPages.forEach((page) => {
         let title = `${pageTitle} ${page.pageno}`;
         let isDisplay = false;
         if (startPage.pageno === page.pageno) {
           title = tocData.title;
           isDisplay = true;
         } else if (lastPage && lastPage.pageno === page.pageno) {
           title = nextToc.title;
           isDisplay = true;
         }

         const pageData = {
           chapterId: page.parentid ? page.parentid.toString() : '',
           chapterTitle: page.title,
           href: page.uri,
           id: page.playOrder,
           pageNumber: page.pageno,
           pageOrder: page.playOrder,
           title: `${title}`,
           type: CHAPTER,
           uri: page.uri,
           actualPageUri: page.uri,
           linkUri: page.uri,
           display: isDisplay,
           audio: page.audio && CommonUtils.setPdfAudioUrl(page.audio, asset)
         };

         tocNode.inBetweenPage.push(pageData);
       });
     } else {
       let playOrder = '';
       let audio = [];
       pagePlaylist.forEach((page) => {
         if (page.pageno === tocData.pageno) {
           playOrder = page.playOrder;
           audio = page.audio;
         }
       });
       tocNode = {
         title: tocData.title,
         linkUri: tocData.uri || '',
         uri: tocData.uri,
         href: tocData.uri,
         actualPageUri: tocData.uri,
         pageOrder: playOrder,
         pageNumber: tocData.pageno,
         type: CHAPTER,
         id: playOrder,
         linkTypeId: tocData.linkTypeID || '',
         regionTypeId: tocData.regionTypeID || '',
         audio: audio && CommonUtils.setPdfAudioUrl(audio, asset)
       };
     }

     return tocNode;
   };

   /**
   * Function to get in between pages of server side PDF
   *
   * @param {Object} tocData
   * @param {Array} pagePlaylist
   * @param {object} nextToc
   */

  static identifySpdfPages = (tocData, pagePlaylist, nextToc, asset) => {
    let tocNode = {};
    const startPage = pagePlaylist.find(page => page.pageno === tocData.pageno);
    const startPageIndex = pagePlaylist.indexOf(startPage);
    const lastPage = nextToc && pagePlaylist.find(page => page.pageno === nextToc.pageno);
    const lastPageIndex = pagePlaylist.indexOf(lastPage);
    const diffIndex = lastPageIndex - startPageIndex;
    const language = Framework.getStoreRegistry().getStore('language');
    const pageTitle = language.getMessage('navigation.PAGE');

    if (nextToc && nextToc.type === SLATE && diffIndex > 1) {
      const inBetweenPages = pagePlaylist.slice(startPageIndex, lastPageIndex);
      tocNode.inBetweenPage = [];

      inBetweenPages.forEach((page) => {
        let title = `${pageTitle} ${page.pageno}`;
        let isDisplay = false;

        if (startPage.pageno === page.pageno) {
          title = tocData.title;
          isDisplay = true;
        } else if (lastPage && lastPage.pageno === page.pageno) {
          title = nextToc.title;
          isDisplay = true;
        }

        const pageData = {
          chapterId: page.parentid ? page.parentid.toString() : '',
          chapterTitle: page.title,
          href: page.playOrder,
          id: page.playOrder,
          pageNumber: page.pageno,
          pageOrder: page.playOrder,
          title: `${title}`,
          label: `${title}`,
          type: CHAPTER,
          uri: page.playOrder,
          linkUri: page.uri,
          actualPageUri: page.uri,
          display: isDisplay,
          parentId: page.parentid,
          audio: page.audio && CommonUtils.setPdfAudioUrl(page.audio, asset)
        };

        tocNode.inBetweenPage.push(pageData);
      });
    } else {
      let playOrder = '';
      let parentId = '';
      let audio = [];
      pagePlaylist.forEach((page) => {
        if (page.pageno === tocData.pageno) {
          parentId = page.parentid;
          playOrder = page.playOrder;
          audio = page.audio;
        }
      });
      tocNode = {
        title: tocData.title,
        label: tocData.title,
        linkUri: tocData.uri || '',
        uri: playOrder,
        href: playOrder,
        actualPageUri: tocData.uri,
        pageOrder: playOrder,
        pageNumber: tocData.pageno,
        type: CHAPTER,
        id: playOrder,
        linkTypeId: tocData.linkTypeID || '',
        regionTypeId: tocData.regionTypeID || '',
        parentId,
        audio: audio && CommonUtils.setPdfAudioUrl(audio, asset)
      };
    }

    return tocNode;
  };
}
