/* eslint-disable */
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Footer component
 *
 * @file Footer.jsx
 * @author Poornima N
 */

import React from 'react';
import { Typography, Link, List, ListItem, withStyles } from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';

import MojoAppIcon from '../../assets/images/MojoAppIcon';
import FooterStyles from './FooterStyles';
import './Footer.scss';
import CommonUtils from '../../common/utils/CommonUtils';
import * as constants from '../../common/constants';
import Framework from '@greenville/framework';

const Footer = ({
  intl, showAppIcon, menuList, classes, downloadApp
}) => {
  const handleFooterClick = (eventLabel, redirectUrl = '') => {
    const customFields = {
      event_value: eventLabel
    }
    CommonUtils.dispatchGaEvent(constants.FOOTER, constants.FOOTER_EVENT, 'Click Link', eventLabel, customFields) 

    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    }
  };
  const language = Framework.getStoreRegistry().getStore('language');

  /**
   * Calls handleFooterClick when enter and spacebar key is pressed
   *
   * @param {*} event
   */
  const handleFooterKeyDown = (event, title, url) => {
    const keyCode = event.code;
    switch (keyCode) {
      case constants.ENTERKEY:
        handleFooterClick(title, url);
        break;
      case constants.SPACEKEY:
        handleFooterClick(title, url);
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className={classes.footerContentWrapper}>
          <div className={`${classes.footercontainer} ${!showAppIcon ? classes.noDownloadAppContainer : ''}`}>
          {showAppIcon &&
              <div className="downloadAppIcon">
                  <Link
                    id="download-app"
                    color="inherit"
                    className={classes.footerapp}
                    onClick={() => handleFooterClick('Download the mobile App', downloadApp.url)}
                    onKeyDown={(event) => handleFooterKeyDown(event, 'Download the mobile App', downloadApp.url)}
                    tabIndex="0"
                  >
                    <MojoAppIcon />
                    <Typography
                      component="p"
                      aria-label={language.getMessage('footer.downloadMobileApp')}
                      className={classes.footerlinkApp}
                    >
                      <FormattedMessage {...language.getText('footer.downloadMobileApp')} />
                    </Typography>
                  </Link>
              </div>
            }
            <div className={classes.listDiv}>
              <List className={classes.footerList}>
                {menuList.map(({
                  id, title, url, target, localeString
                }) =>
                (<ListItem className={classes.listItemStyle} key={id} >
                  <Link
                    onClick={() => handleFooterClick(title, url)}
                    onKeyDown={(event) => handleFooterKeyDown(event, title, url)}
                    color="primary"
                    target={target}
                    rel="noopener"
                    className={classes.footerlink}
                    aria-label={language.getMessage(title)}
                    tabindex="0"
                  >
                    <Typography
                      component="p"
                      className={classes.footersection}
                    >
                      {language.getMessage(title)}
                    </Typography>
                  </Link>
                </ListItem>)
                )}
              </List>
            </div>
            <Typography
              component="p"
              className={`${classes.footeryear} ${classes.footercopyrightsection}`}
            >
            © 1996–{new Date().getFullYear()} {language.getMessage('footer.copyrights')}
            </Typography>
          </div>
        </div>
    </React.Fragment>

  );
};


export default withStyles(FooterStyles)(injectIntl(Footer));
