/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to book for authome
 *
 * @file Book.js
 * @author Mohamed yasar arabath M
 */

import { types } from 'mobx-state-tree';

import Banner from './BannerMapping';

/**
 * A mobx model for course details
 *
 */
const CourseDetails = types.model(
  'CourseDetails',
  {
    course_id: types.maybeNull(types.string),
    course_name: types.maybeNull(types.string),
    course_start_date: types.maybeNull(types.string),
    course_end_date: types.maybeNull(types.string),
    user_role: types.maybeNull(types.string)
  }
);

/**
   * A mobx model for subscription details
   *
   */
const Subscription = types.model(
  'Subscription',
  {
    type: types.maybeNull(types.string),
    business_model_code: types.maybeNull(types.string),
    start_date: types.maybeNull(types.string),
    end_date: types.maybeNull(types.string),
    expired: types.maybeNull(types.boolean),
    subscription_id: types.maybeNull(types.string),
    user_role: types.maybeNull(types.string)
  }
);

/**
   * A mobx model for gps subscription of the book
   *
   */
const gpsSubscriptionOfBook = types.model(
  'GpsSubscriptionOfBook',
  {
    status: types.maybeNull(types.string),
    entitlementLevel: types.maybeNull(types.string),
    subscriptionSource: types.maybeNull(types.string)
  }
);

/**
   * A mobx model for book
   *
   */
const Book = types.model(
  'Book',
  {
    book_id: types.maybeNull(types.string),
    book_title: types.maybeNull(types.string),
    course_id: types.maybeNull(types.string),
    courseName: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    course_expired: types.maybeNull(types.boolean),
    expired: types.maybeNull(types.boolean),
    entitlement_source: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    cover_image_url: types.maybeNull(types.string),
    book_type: types.maybeNull(types.string),
    author: types.maybeNull(types.string),
    index_id: types.maybeNull(types.string),
    version: types.maybeNull(types.string),
    product_id: types.maybeNull(types.string),
    product_model: types.maybeNull(types.string),
    isbn: types.maybeNull(types.union(types.integer, types.string)),
    ppId: types.maybeNull(types.union(types.integer, types.string)),
    language: types.maybeNull(types.string),
    user_id: types.maybeNull(types.string),
    study_guide_available: types.maybeNull(types.union(types.boolean, types.string)),
    audio_book_supported: types.maybeNull(types.string),
    courses: types.maybeNull(types.array(CourseDetails)),
    product_entitlements: types.maybeNull(Subscription),
    last_activity: types.maybeNull(types.string),
    last_location: types.maybeNull(types.string),
    last_location_id: types.maybeNull(types.string),
    last_location_read: types.maybeNull(types.string),
    last_location_read_id: types.maybeNull(types.string),
    last_accessed_time: types.maybeNull(types.Date),
    last_accessed_print_page_number: types.maybeNull(types.string),
    social_supported: types.maybeNull(types.boolean),
    product_model_name: types.maybeNull(types.string),
    bannerData: types.maybeNull(Banner),
    hasMultipleCourse: types.maybeNull(types.boolean),
    key: types.maybeNull(types.string),
    gpsSubscriptionOfBook: types.maybeNull(gpsSubscriptionOfBook),
    showRemoveEntitlement: types.optional(types.boolean, false),
    showAudio: types.optional(types.boolean, false),
    showBookmark: types.optional(types.boolean, false),
    showFlashcard: types.optional(types.boolean, false),
    mappedChannel: types.maybeNull(Banner),
    channel_id: types.maybeNull(types.string),
    channel_name: types.maybeNull(types.string),
    collections: types.maybeNull(types.array(types.maybeNull(types.string))),
    isArchived: types.optional(types.boolean, false),
    showArchive: types.optional(types.boolean, false),
    showUnArchive: types.optional(types.boolean, false),
    printPageAvailable: types.optional(types.boolean, false)
  }
);
export default Book;
