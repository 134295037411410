/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/* eslint-disable max-len */

/**
 * QRCodeIcon icon
 *
 * @file QRCodeIcon.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const QRCodeIcon = () => (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M3,11h8V3H3V11z M5,5h4v4H5V5z" /><path d="M3,21h8v-8H3V21z M5,15h4v4H5V15z" /><path d="M13,3v8h8V3H13z M19,9h-4V5h4V9z" /><rect height="2" width="2" x="19" y="19" /><rect height="2" width="2" x="13" y="13" /><rect height="2" width="2" x="15" y="15" /><rect height="2" width="2" x="13" y="17" /><rect height="2" width="2" x="15" y="19" /><rect height="2" width="2" x="17" y="17" /><rect height="2" width="2" x="17" y="13" /><rect height="2" width="2" x="19" y="15" /></g></g>
    </SvgIcon>
);

export default QRCodeIcon;

