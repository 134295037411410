/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Mohamed yasar arabath M
 */

import Framework from '@greenville/framework';
import { getSnapshot } from 'mobx-state-tree';
import CommonUtils from '../../../common/utils/CommonUtils';
import * as constants from '../../../common/constants';
import UserPreferencesService from '../service/UserPreferencesService';

export default class CoursePlugin {
  constructor(context, location = constants.AUTH_HOME) {
    this.authHomeContext = context;
    this.init();
    this.userPreferencesService = new UserPreferencesService();
    this.location = location;
  }

  static courseCollectionLimit = null;

  static showSeeAll = false;

  init = () => {
    Framework.getEventManager().on(constants.COURSE_COLLECTION_SERVICE_FETCHED, (eventData) => {
      if (eventData?.method === constants.HTTP_REQUEST_METHODS.POST && !eventData.isBackgroundSave) {
        if (eventData.payload && eventData.payload.length > 0) {
          const channeData = eventData.payload[0];
          this.logRecentCourse(channeData.name);
          this.authHomeContext.usersCollections.push({ name: channeData.name });
          this.setCourse(channeData.name);
        }
      } else if (eventData?.method === constants.HTTP_REQUEST_METHODS.DELETE) {
        if (eventData.payload) {
          const channeData = eventData.payload;
          const foundIndexToDelete = this.authHomeContext.usersCollections.findIndex(
            channel => channel.name === channeData.name
          );
          if (foundIndexToDelete !== -1) {
            this.authHomeContext.usersCollections.splice(foundIndexToDelete, 1);
            this.setCourse();
          }
        }
      }
    });
  }

  handlePopularChannelCardClick = (index = null, channelId = null) => {
    const { herobanner } = this.authHomeContext.props;
    if (index === 0 || index) {
      const popularChannelsData = getSnapshot(herobanner).popularChannels;
      const popularCards = CommonUtils.getActiveChannelCards(popularChannelsData.channelCards);
      const findChannelCard = popularCards[index];
      if (findChannelCard) {
        this.saveCollections([this.constructPayloadList(findChannelCard.channelId, findChannelCard.channelId)]);
      }
    } else if (channelId) {
      this.saveCollections([this.constructPayloadList(channelId, channelId)]);
    }
    if (!channelId) {
      CommonUtils.dispatchGaEvent(
        constants.AUTHHOME_APPLICATION,
        constants.EVENT_USER_INTERACTION,
        constants.ADD_COURSE_EVENT.ACTION_NAME,
        null,
        {
          location_in_app: constants.LOCATION_IN_APP.NEW_OTHER_COURSE_CONTAINER,
          container_value: CommonUtils.getCurrentScreenEventLabel()
        }
      );
    }
  }

  getBookData = book => ({
    productId: book.product_id,
    courseId: book.course_id,
    bookId: book.book_id,
    template: book.channel_id ? constants.HERO_BANNER_TYPES.BOOK_WITH_CHANNEL : constants.HERO_BANNER_TYPES.BOOK,
    title: book.book_title,
    subStartDate: book?.product_entitlements?.start_date,
    channelId: book.channel_id,
    bookCoverImage: book.cover_image_url,
    bmc: book?.product_entitlements?.business_model_code
  })

  constructBookCourse = (book, activeTab, options = {}) => {
    if (!book || CommonUtils.isEmpty(book)) return {};

    let title = book.title;

    if (options?.showCourse) {
      title = book.courseName;
    }

    return {
      channelId: book.channel_id,
      imageSrc: book.cover_image_url,
      title,
      id: `${book.book_id}:${book.product_id}:${book.course_id}`,
      active: activeTab === `${book.book_id}:${book.product_id}:${book.course_id}`,
      collection: [this.getBookData(book)],
      isMLMcourse: !!options.showCourse
    };
  }

  constructChannelCourse = (book, channel, activeTab) => {
    if (!book || CommonUtils.isEmpty(book)) return {};
    const currentBookChannelId = book.channel_id;
    const {
      title
    } = channel || {};

    return {
      channelId: currentBookChannelId,
      imageSrc: book.cover_image_url,
      title,
      id: currentBookChannelId,
      active: activeTab === currentBookChannelId,
      collection: [this.getBookData(book)],
      isChannelMapped: true
    };
  }

  saveCollections = (payload, isBackgroundSave = false) => {
    Framework.getEventManager().publish(constants.COURSE_COLLECTION_SERVICE_REQUESTED,
      {
        method: constants.HTTP_REQUEST_METHODS.POST,
        isBackgroundSave,
        payload
      });
  }

  constructPayloadList = (name, referenceId, titles) => (titles && titles.length > 0
    ? { name, referenceId, titles }
    : { name, referenceId })

  getMissingItems = (list1, list2) => {
    const setA = new Set(list1);
    const setB = new Set(list2);
    const missingItems = [...setA].filter(element => !setB.has(element));

    return missingItems;
  }

  getBookDataFromCollection = (collection) => {
    let data = [];

    if (collection && collection.length > 0) {
      data = collection.map(({ bookId, bmc }) => ({ bookId, bmc }));
    }

    return data;
  }

  /**
   * Set course list
   */
  setCourse = (activeTabId, initialLoad = false) => {
    const { bookshelf, herobanner, coursecollection } = this.authHomeContext.props;
    const books = getSnapshot(bookshelf).books;
    const popularChannelsData = getSnapshot(herobanner).popularChannels;
    const { userActivity = { } } = this.authHomeContext.userPreferences;
    const courseCollectionLimit = this.authHomeContext.getContainerData(
      'heroView',
      constants.COURSE_COLLECTION_LIMIT_KEY
    ) || constants.COURSE_COLLECTION_LIMIT;
    CoursePlugin.courseCollectionLimit = courseCollectionLimit;

    const activeTab = activeTabId || CommonUtils.getObjectPropertyValue(userActivity, 'recentCourseTab', null);

    const activeBooks = CommonUtils.getActiveBooks(books);

    let courses = [];

    const allocatedChannelId = [];

    // Adding book course
    if (activeBooks.length > 0) {
      const { unArchivedBooks } = CommonUtils.getArchivedOrUnArchivedBooks(activeBooks);
      unArchivedBooks.forEach((book) => {
        const currentBookChannelId = book.channel_id;
        const BMC = book?.product_entitlements?.business_model_code;
        const isMLMBook = CommonUtils.isMyLabSubscription(book?.course_id, BMC);

        // Book without channel
        if (!currentBookChannelId) {
          courses.push(this.constructBookCourse(book, activeTab, { showCourse: isMLMBook }));
        } else if (currentBookChannelId) {
          const channelAddedIndex = courses.findIndex(currentBook => currentBook.channelId === currentBookChannelId);
          const shouldAssociateTitleInCollection = channelAddedIndex > -1 && !isMLMBook;

          if (shouldAssociateTitleInCollection) {
            const {
              collection
            } = courses[channelAddedIndex];

            if (Array.isArray(collection)) {
              collection.push(this.getBookData(book));
            }
          } else {
            const {
              title,
              thumbnailUrl
            } = herobanner.getChannelData(currentBookChannelId) || {};
            allocatedChannelId.push(currentBookChannelId);
            const renderCourse = isMLMBook
              ? this.constructBookCourse(book, activeTab, { showCourse: isMLMBook })
              : this.constructChannelCourse(book, { title, thumbnailUrl }, activeTab);

            courses.push(renderCourse);
          }
        }
      });
    }

    // Adding Channel course
    const collectionTobeAdd = [];
    if (
      this.authHomeContext.usersCollections
      && this.authHomeContext.usersCollections.length > 0
    ) {
      this.authHomeContext.usersCollections.forEach((collectionData) => {
        const collectionRecord = courses.find(course => (course.id === collectionData.name));

        if (collectionRecord) {
          const listOfBookIds = collectionRecord.collection.map(book => book.bookId);
          const bookIdsInCollection = (collectionData.titles && collectionData.titles.map(book => book.bookId)) || [];
          const misMatchBookIds = this.getMissingItems(listOfBookIds, bookIdsInCollection);

          if (misMatchBookIds.length > 0) {
            const collectionName = collectionRecord.id;
            const titles = this.getBookDataFromCollection(collectionRecord.collection);

            collectionTobeAdd.push(this.constructPayloadList(collectionName, collectionName, titles));
          }
        } else {
          const channelData = herobanner.getChannelData(collectionData.name) || {};

          if (channelData && !CommonUtils.isEmpty(channelData)) {
            courses.push({
              channelId: collectionData.name,
              id: collectionData.name,
              imageSrc: channelData.thumbnailUrl,
              title: channelData.title,
              active: activeTab === collectionData.name,
              isChannelOnly: true,
              collection: [{
                template: constants.HERO_BANNER_TYPES.CHANNEL,
                channelId: collectionData.name,
                title: channelData.title
              }]
            });
            CoursePlugin.courseCollectionLimit += 1;
          }
        }
      });
    } else if (courses.length > 0) {
      // No data in collection add MLM and Book with Channel course in collection
      courses.forEach((course) => {
        // isMappedChannel book and MLM course
        if (course.isChannelMapped || course.isMLMcourse) {
          const collectionName = course.id;
          const titles = this.getBookDataFromCollection(course.collection);

          collectionTobeAdd.push(this.constructPayloadList(collectionName, collectionName, titles));
        }
      });
    } else if (activeTabId) {
      // Add channel course if any
      const channelData = herobanner.getChannelData(activeTabId) || {};

      if (channelData && !CommonUtils.isEmpty(channelData)) {
        courses.push({
          channelId: channelData.channelId,
          id: channelData.channelId,
          imageSrc: channelData.thumbnailUrl,
          title: channelData.title,
          active: activeTab === channelData.channelId,
          isChannelOnly: true,
          collection: [{
            template: constants.HERO_BANNER_TYPES.CHANNEL,
            channelId: channelData.channelId,
            title: channelData.title
          }]
        });
        CoursePlugin.courseCollectionLimit += 1;
      }
    }

    // last accessed channel
    // const { channel, location } = CommonUtils.getRecentlyUsedChannel() || {};
    // if (channel && location) {
    //   const channelAddedIndex = courses.findIndex(course => course.channelId === location?.id);
    //   if (channelAddedIndex === -1) {
    //     courses.push({
    //       channelId: location?.id,
    //       id: location?.id,
    //       imageSrc: channel?.thumbnail,
    //       title: channel?.title,
    //       active: activeTab === location?.id,
    //       isChannelOnly: true,
    //       collection: [{
    //         template: constants.HERO_BANNER_TYPES.CHANNEL,
    //         channelId: location?.id,
    //         title: channel?.title
    //       }]
    //     });
    //     CoursePlugin.courseCollectionLimit += 1;
    //     collectionTobeAdd.push(this.constructPayloadList(location?.id, location?.id));
    //   }
    // }

    // Auto background save collection
    if (collectionTobeAdd.length > 0) {
      let collections = collectionTobeAdd;

      if (CoursePlugin.courseCollectionLimit) {
        collections = collectionTobeAdd.slice(0, CoursePlugin.courseCollectionLimit);
      }

      this.saveCollections(collections, true);
    }

    courses = this.sortCourses(courses, initialLoad);

    const channelIdList = [];
    courses.forEach((course) => {
      if (course.channelId) {
        channelIdList.push(course.channelId);
      }
    });

    coursecollection.set(courses, activeTab?.id);
    if (popularChannelsData && popularChannelsData.channelCards) {
      const channelCards = popularChannelsData.channelCards.map((channelData) => {
        let renderChannel = channelData;
        const hasChannelInCourseTabs = channelIdList.includes(renderChannel.channelId);
        renderChannel = { ...renderChannel, active: !hasChannelInCourseTabs };

        return renderChannel;
      });
      herobanner.setPopularChannels(channelCards);
    }

    this.authHomeContext.heroDashboardPlugin.setHeroDashboard({
      fetchChannels: true,
      courseList: courses,
      isInitialLoad: true
    });
  }

  getDeeplinkBookIdx = (courses) => {
    const {
      flags
    } = this.authHomeContext?.state;
    const IABookIdx = CommonUtils.getIAbookIdxIfAvailable(courses);
    const isMLMroute = this.location === constants.MLM_AUTH_HOME && flags?.enableMLMAuthHomeRedirection;
    const {
      match
    } = this.authHomeContext?.props;
    const MLMBookIdx = CommonUtils.getMLMbookIdxIfAvailable(courses, match, isMLMroute);

    if (MLMBookIdx > -1) return MLMBookIdx;

    if (IABookIdx > -1) return IABookIdx;

    return -1;
  }

  getBookWithChannelCourses = courses => courses.filter(course => course.isChannelMapped);

  getChannelOnlyCourses = courses => courses.filter(course => course.isChannelOnly);

  getMlmCourses = courses => courses.filter(course => course.isMLMcourse);

  getOtherCourses = courses => courses.filter(course => !course.isMLMcourse
    && !course.isChannelMapped
    && !course.isChannelOnly);

  sortCourses = (courses, initialLoad = false) => {
    if (courses?.length <= 0) return [];

    const courseCollectionLimit = this.authHomeContext.getContainerData(
      'heroView',
      constants.COURSE_COLLECTION_LIMIT_KEY
    ) || constants.COURSE_COLLECTION_LIMIT;
    let renderCourses = [...courses];

    renderCourses.sort((a, b) => a.title && b.title && a.title.localeCompare(b.title));

    renderCourses.forEach((course) => {
      if (course && course.collection && course.collection.length > 1) {
        course.collection.sort((a, b) => new Date(b?.subStartDate) - new Date(a?.subStartDate));
        const { bookCoverImage } = course.collection[0];
        // eslint-disable-next-line no-param-reassign
        course.imageSrc = bookCoverImage;
      }
    });

    const bookWithChannelCourses = this.getBookWithChannelCourses(renderCourses);
    const bookWithMLMCourses = this.getMlmCourses(renderCourses);
    const otherBookCourses = this.getOtherCourses(renderCourses);
    const channelOnlyCourses = this.getChannelOnlyCourses(renderCourses);
    const showSeeAll = (
      bookWithChannelCourses.length + bookWithMLMCourses.length + otherBookCourses.length
    ) > courseCollectionLimit;

    CoursePlugin.showSeeAll = showSeeAll;

    renderCourses = [...bookWithChannelCourses,
      ...channelOnlyCourses,
      ...bookWithMLMCourses,
      ...otherBookCourses];
    let activeCourseTabIndex = renderCourses.findIndex(({ active }) => active);


    if (activeCourseTabIndex >= 0) {
      const activeCourseTab = renderCourses.splice(activeCourseTabIndex, 1);
      renderCourses = [...activeCourseTab, ...renderCourses];
    } else {
      const activeCourseTab = renderCourses.splice(0, 1);
      activeCourseTab[0].active = true;
      renderCourses = [...activeCourseTab, ...renderCourses];
    }

    activeCourseTabIndex = 0;
    const deepLinkBookIdx = this.getDeeplinkBookIdx(renderCourses);
    const isDeepLinkBookAlreadyAtFront = deepLinkBookIdx === 0;

    if ((deepLinkBookIdx > -1) && initialLoad && !isDeepLinkBookAlreadyAtFront) {
      const deepLinkBook = renderCourses[deepLinkBookIdx];
      const currentActiveCourseIdx = activeCourseTabIndex;
      const currentActiveBook = renderCourses[currentActiveCourseIdx];
      deepLinkBook.active = true;
      currentActiveBook.active = false;
      renderCourses.splice(deepLinkBookIdx, 1);

      renderCourses = [deepLinkBook, ...renderCourses];
    }

    if (CoursePlugin.courseCollectionLimit) {
      renderCourses = renderCourses.slice(0, CoursePlugin.courseCollectionLimit);
    }

    return renderCourses;
  }

  /**
   * Trigger add course popup
   */
  addCourse = () => {
    this.authHomeContext.onboardingPlugin.showCourseOnBoard();
    CommonUtils.dispatchGaEvent(
      constants.AUTHHOME_APPLICATION,
      constants.EVENT_USER_INTERACTION,
      constants.ADD_COURSE_EVENT.ACTION_NAME,
      null,
      {
        location_in_app: constants.LOCATION_IN_APP.ADD_COURSE_CTA,
        container_value: CommonUtils.getCurrentScreenEventLabel()
      }
    );
  }

  /**
   * Handle course on select
   *
   * @param {*} e
   * @param {*} courseId
   */
  handleCourseClick = (e, courseId) => {
    const { coursecollection } = this.authHomeContext.props;
    const { activeHeaderL2Tab } = this.authHomeContext?.state || {};
    const courseListState = coursecollection.getCourseList();
    const currentActiveCourseIndex = courseListState?.findIndex(course => course?.active);
    const currentActiveCourse = courseListState[currentActiveCourseIndex];

    // Redirect to L1 if it is not already in L1
    if (activeHeaderL2Tab && activeHeaderL2Tab !== '') {
      this.authHomeContext.onBackClick();
    }
    // Click on same course tab does nothing
    if (currentActiveCourse?.id === courseId) {
      return;
    }
    this.logRecentCourse(courseId);
    const courseList = courseListState.map((course) => {
      const renderCourse = { ...course };
      if (renderCourse.id === courseId) {
        renderCourse.active = true;
      } else {
        renderCourse.active = false;
      }

      return renderCourse;
    });
    coursecollection.set(courseList, courseId);
    this.authHomeContext.heroDashboardPlugin.setHeroDashboard({ fetchChannels: true, courseList });
  }

  logRecentCourse = (id) => {
    if (!id) {
      return;
    }
    const eventData = {
      eventType: 'PUT',
      payload: {
        userActivity: {
          ...this.authHomeContext.userPreferences.userActivity,
          recentCourseTab: id
        }
      }
    };
    Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED, eventData);
  }
}
