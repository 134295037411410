/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for French language
 *
 * @file fr.js
 * @author Manimaran.S
 */

const fr = {
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% to download the app on another device and automatically sign in or to use your unique QR code to securely sign in on the app you\'ve already installed.',
  'moremenu.downloadAppNotDownload': '%Click here% to download the app and securely sign in with your unique QR code.',
  'channel.bundleTitle': 'Pack d’accès aux études et de préparation aux examens pour vos autres cours',
  'channel.noBundletitle': 'Étude et préparation aux examens pour vos autres cours',
  'onboard.welcome': 'Bienvenue à Pearson+',
  'onboard.welcomeCourse': 'Vous êtes au bon endroit pour accéder à <span>{COURSE_NAME}</span> et bien plus encore.',
  'onboard.welcomeCourseList': 'Votre achat comprend :',
  'onboard.next': 'Suivant',
  'onboard.studyPlan': 'Custom study plan created!',
  'onboard.addCourseAnother': 'Voulez-vous ajouter un autre cours ?',
  'onboard.addCourseWithoutEtextBundle': 'Ajouter un cours via Étude et préparation aux examens',
  'onboard.doYouWantOtherCourse': 'Voulez-vous ajouter un autre cours ?',
  'onboard.purchase': 'J\'ai besoin d\'acheter un eTextbook de Pearson',
  'onboard.addCourseWithoutEtext': 'Ajouter un cours sans eTextbook',
  'onboard.mayBeLater': 'Peut-être plus tard',
  'onboard.aboutCourse': 'Parlez-nous de votre cours',
  'onboard.close': 'Close',
  'course.addTitle': 'Ajouter un cours',
  'bookshelf.title': 'Ma bibliothèque',
  'common.LOGOUT': 'Déconnexion',
  'common.GO_HOME': 'Aller page d\'accueil',
  'common.PURCHASE': 'Acheter',
  'home.action.GOTO_COURSE': 'Aller au cours',
  'courses.WELCOME': 'Bonjour {userName}, bienvenue dans votre cours {courseName}!',
  'courses.toc.PUBLISH_SUCCESS': 'Succès!',
  'courses.toc.PUBLISH_MESSAGE': 'Les étudiants verront vos modifications la prochaine fois quils ouvriront du contenu.', /* eslint-disable-line max-len */
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Abonnement introuvable',
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Votre compte ne permet pas l\'accès à ce titre. Si vous pensez avoir reçu ce message par erreur veuillez contacter le support technique.', /* eslint-disable-line max-len */
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Abonnement introuvable',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Votre abonnement n\'inclut pas l\'accès au eText, mais vous pouvez l\'obtenir dès maintenant en utilisant une carte de crédit.', /* eslint-disable-line max-len */
  'foxit.ABOUT': 'À propos',
  'foxit.COPY_RIGHT': 'Liseuse PDF optimisé par Foxit. Copyright (C) 2003-2019 par Foxit Software Incorporated',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  'link.SUPPORT': 'https://pearsonerpi.com/aide',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.CONTACT_SUPPORT': 'Contacter l"assistance',
  'menu.GET_THE_APP_DOWNLOAD': 'Télécharger l\'application mobile',
  'menu.ABOUT': 'À propos',
  'menu.FOXIT': 'Optimisé par FoxIT',
  'menu.TERMS_OF_USE': 'Conditions d"utilisations',
  'menu.PRIVACY_POLICY': 'Politique de confidentialité',
  'menu.SIGN_OUT': 'Se déconnecter',
  'menu.PRINT_PAGE': 'Imprimer la page',
  'menu.GET_LOOSE_LEAF': 'Obtenir le manuel en format feuilles mobiles',
  'menu.AVAILABLE': 'Également disponible',
  'menu.PRINT_VERSION': 'Besoin d"une version imprimée?',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Tous droits réservés.',
  'menu.tooltip.SCHEDULE': 'Programme',
  'print.FOOTER_MESSAGE': 'Imprimé par {firstName} {lastName} ({userEmailId}) le {currDate}.',
  'print.WATERMARK': 'Droit d\'auteur © {year} Pearson Education',
  'product.WELCOME': 'Bonjour {userName}',
  'device.SWAPPED_ALREADY': 'Vous avez déjà échangé ce mois-ci',
  'device.SWITCH_DEVICE': 'Passer à l\'appareil actuel ?',
  'device.SWITCH_ONCE': 'Puisque vous ne pouvez changer qu\'une fois par mois, vous ne pourrez pas annuler cette action.',
  'device.SEAT_TAKEN': 'Ce siège est pris',
  'device.DISCONNECT_DEVICE': 'Vous ne pouvez vous connecter qu\'à 3 appareils. Pour continuer ici, débranchez un autre appareil.',
  'device.ALERT_TEXT': 'N\'oubliez pas que vous ne pouvez échanger des appareils qu\'une fois par mois.',
  'device.YOUR_MOJO': 'Vous êtes en train de vous rendre sur Mojo',
  'device.DISCONNECT': 'Déconnecter',
  'device.CONTINUE': 'Continuer',
  'device.HEADER': 'Finir votre autre session ?',
  'device.CLOSE': 'Retour à l\'accueil',
  'device.CODE': 'Pour vous déconnecter et continuer ici, saisissez le code d\'authentification envoyé à {maskedEmail}.',
  'device.TIMER': 'Vous avez {time} pour saisir votre code ici',
  'device.RESEND': '',
  'device.RESEND_NAME': '',
  'device.CONTACT': '',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Erreur : vérifiez votre code ou renvoyez-le.',
  'device.MANY_ATTEMPTS': 'Trop de tentatives, essayez de renvoyer le code',
  'device.EXPIRED': 'Votre temps est écoulé, essayez de renvoyer le code',
  'device.ACCESS_HEADER': 'Vous vous êtes connecté !',
  'device.ACCESS_DESC': 'Votre session a été authentifiée. Vous voulez mettre à jour votre mot de passe pendant que vous y êtes ?',
  'device.ACCESS_CHANGE_PASSWORD': 'Modifier le mot de passe',
  'device.ACCESS_RESET_PASSWORD': 'Réinitialiser le mot de passe',
  'device.THREATONE_TITLE': 'Tout va bien ?',
  'device.THREATONE_DESC': 'Nous avons remarqué une activité suspecte sur votre compte.',
  'device.THREATONE_DESC_TWO': 'C\'est le bon moment pour changer de mot de passe.',
  'device.MAY_BE_LATER': 'Peut-être plus tard',
  'device.OKAY': 'OK',
  'device.ACCOUNT_LOCK_DESC': 'Pour assurer votre sécurité, nous vous déconnectons. Nous avons envoyé un lien de réinitialisation de mot de passe à l\'e-mail associé à votre compte.',
  'device.ACCOUNT_LOCK_TITLE': 'Nous avons remarqué une activité suspecte sur votre compte',
  'device.ACCOUNT_LOCK_TIMER': 'Vous serez déconnecté dans {time}',
  'menu.myAccount': 'My account (Mon compte)',
  'menu.helpCenter': 'Centre d’aide',
  'menu.preferencesCenter': 'Centre de préférences',
  'menu.HELP_SUPPORT': 'Aide et support technique',
  'menu.FAQS': 'FOIRE AUX QUESTIONS',
  'menu.CHAT_WITH_SUPPORT': 'Discutez avec l\'assistance technique',
  'menu.QUICK_WALKTHROUGH': 'Parcours rapide',
  'menu.LEGAL': 'Département juridique',
  'menu.PRIVACY_NOTICE': 'Avis de confidentialité',
  'menu.TITLE_INFO': 'Info Titre',
  'menu.PEARSON_ACCESSIBILITY': 'Accessibilité Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Enregistrez {deviceLimit} appareils. Échangez-les 1 par mois.',
  'menu.ACCESSIBILITY': 'Accessibilité',
  'menu.ACCESSIBILITY_SUPPORT': 'Soutien à l\'accessibilité',
  'menu.DEVICES': 'Récent appareils',
  'menu.THIS_DEVICE': 'Cet appareil',
  'menu.MOJO_APP_INFO': 'Rendez-vous dans l\'App Store sur votre téléphone ou votre tablette pour utiliser Pearson+ hors ligne.',
  'menu.DEVICE_LABEL': 'Appareil',
  'navigation.PAGE': 'Page',
  'launchcode.CREATEBY': 'Créé par :',
  'launchcode.sentvia': 'Envoyé via:',
  'launchcode.CODEINLAST': 'Codes des 7 derniers jours :',
  'launchcode.TIMESENT': 'Heure d\'envoi :',
  'launchcode.ACCOUNTEMAIL': 'E-mail du compte :',
  'launchcode.LAUNCHDIALOGTITLE': 'Journal de code OTP',
  'menu.OTP_CODES': 'Codes OTP',
  'menu.LAUNCH_CODE': 'Lancer le journal de code',
  'menu.SEND_CODE': 'Envoyer un nouveau code d\'utilisateur par e-mail',
  'menu.SEND_CODE_PHONE': 'Envoyer un nouveau code d\'utilisateur par SMS',
  'menu.COMMUNITY_GUIDELINES': 'Orientations communautaires',
  'textmessage.TITLE': 'Entrer un numéro de téléphone',
  'textmessage.PHONE_NUMBER': 'Numéro de téléphone',
  'textmessage.SEND_CODE': 'Envoyer un code',
  'textmessage.INVALID_NUMBER': 'Numéro de téléphone invalide.',
  'textmessage.SUCCESS_MESSAGE': 'Code envoyé',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Recherchez votre liste',
  'remove.title': 'Supprimer le titre?',
  'remove.subTitle': 'Vous pouvez toujours la récupérer plus tard - il suffit d\'utiliser la recherche pour l\'ajouter à nouveau.',
  'remove.buttonText': 'Supprimer',
  'footer.downloadMobileApp': 'Télécharger l\'application mobile',
  'footer.termsOfUse': 'Conditions d\'utilisation',
  'footer.privacy': 'Confidentialité',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'Ne pas vendre mes informations personnelles',
  'footer.accessibility': 'Accessibilité',
  'footer.patentNotice': 'Avis de brevet',
  'footer.copyrights': 'Pearson Tous droits réservés.',
  'channel.videos': 'Vidéos',
  'channel.questions': 'Questions',
  'channel.descriptionA': 'Complétez votre lecture avec les Canaux.',
  'channel.descriptionB': 'Obtenez de l\'aide sur des sujets difficiles avec les Canaux.',
  'channel.openchannel': 'Ouvrir une chaîne',
  'hero.continueReading': 'Poursuivre la lecture',
  'hero.openBook': 'Ouvrir le manuel',
  'hero.channel.open': 'Ouvrir une chaîne',
  'hero.addATitle': 'Ajouter un titre',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Afficher les titres inactifs',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Find your eTextbook'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Open etextbook',
  'hero.openEtextBookV1': 'Open eTextbook',
  'hero.heroHeading': 'Parce que vous étudiez <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Pack d\'étude et de préparation aux examens',
  'hero.study.help': 'Obtenez une aide à l\'étude',
  'hero.study.jumpBackIn': 'Revenir en arrière',
  'hero.study.numOfUsers': 'Actuellement, <b>{num_of_users}</b> étudiants utilisent les Canaux.',
  'hero.heroBook.sectionHeading': 'Débloquez votre formation',
  'hero.heroBook.channelsSectionHeading': 'Commencer une nouvelle session d’étude',
  'hero.heroBook.sectionHeadingNewUser': 'Aide à l\'étude disponible ici',
  'hero.heroBook.sectionSubHeading': 'Décomposez les sujets difficiles avec des questions pratiques et des explications vidéo adaptées à votre cours.',
  'hero.heroChannel.cardsHeading': 'Poursuivez là où vous vous êtes arrêté',
  'hero.marketingcard.subheading': 'Étudiez plus efficacement grâce à des vidéos qui expliquent les concepts difficiles et des problèmes pratiques supplémentaires, tous adaptés à votre cours.',
  'hero.marketingcard1.copy.title': 'Apprenez avec des vidéos',
  'hero.marketingcard1.copy.description': 'Regardez des vidéos d’explication qui décomposent les sujets délicats.',
  'hero.marketingcard2.copy.title': 'Entraînez-vous aux problèmes',
  'hero.marketingcard2.copy.description': 'Préparez votre examen avec des milliers de questions similaires à celles de l\'examen et des solutions vidéo',
  'hero.marketingcard3.copy.title': 'Obtenez une aide expérimentée',
  'hero.marketingcard3.copy.description': 'Posez des questions et obtenez des réponses d’experts en la matière.',
  'hero.popularTopics.heading': 'Autres sujets populaires sur ce Canal',
  'hero.popularChannels.heading': 'Canaux populaires',
  'hero.popularChannels.headingv1': 'Vous désirez suivre un autre cours ?',
  'hero.popularChannels.description': 'Obtenez de l’aide pour étudier avec ces canaux de haut niveau.',
  'hero.popularChannels.descriptionv1': 'Obtenez de l’aide pour d’autres cours en choisissant un canal.',
  'hero.popularChannels.descriptionv2': 'Passez en revue les sujets d\'aide à l\'étude les plus populaires.',
  'hero.startReading': 'Commencer la lecture',
  'hero.practice': 'Pratique',
  'hero.noTitleNoChannel.sectionHeading': 'Préparez-vous à vos examens en un temps record',
  'hero.herobook.mappedChannelHeading': 'Aide à l\'étude recommandée sur ce Canal',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': 'Retour',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Bite size videos that break down tricky topics',
  'hero.promotionalcard.list2': 'Thousands of exam like questions',
  'hero.promotionalcard.list3': 'Get answers from subject matter experts',
  'hero.studyandexamprep': 'Étude et préparation aux examens',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Learn more',
  'hero.subscriptionStatusTitle': 'Statut de l’abonnement à Pearson+',
  'hero.eTextbook': 'eTextbook',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Accéder à la page',
  'subscription.eTextbook': 'eTextbook',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': 'Pack d\'étude et de préparation aux examens',
  'subscription.studyNoBundle': 'Étude et préparation aux examens',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': 'Continuer l’étude',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'Find your eTextbook',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Remove course from dashboard?',
  'hero.removecourse.description': 'You can always add this course back to your dashboard by clicking "Add course".',
  'hero.removecourse.primaryCtaText': 'Remove',
  'hero.removecourse.secondaryCtaText': 'Cancel',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Search eTextbooks',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Apprendre une nouvelle langue gratuitement',
  'hero.mondly.learnLang.title2': 'Apprendre une nouvelle langue gratuitement!',
  'hero.mondly.subTitle': 'Les utilisateurs du livre électronique de Pearson bénéficient de trois mois gratuits de Mondly by Pearson.',
  'hero.mondly.description': 'La majeure partie des applications d’apprentissage des langues ne vous permettent d’apprendre qu’en anglais. Pourtant, il est préférable d’apprendre dans votre langue maternelle. Mondly vous permet d’apprendre dans n’importe laquelle de nos 41 langues.',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.learnLanguage': 'Apprendre une langue',
  'hero.subscriptionStatusTitle2': 'Vos ressources Pearson+',
  'hero.extractingExamDetails': 'Extraction des détails de l’examen',
  'hero.finishingTheProcess': 'Fin du processus',
  'hero.hereWhatWeGotFromYourSyllabus': 'Voici ce que nous avons obtenu à partir de votre programme d\'études',
  'hero.school': 'Établissement :',
  'hero.class': 'Classe :',
  'hero.textbook': 'Manuel scolaire :',
  'hero.examSchedule': 'Programme d’examen:',
  'hero.confirm': 'Confirmer',
  'onboard.studyMaterials': 'Choisissez votre matière et nous créerons un tableau de bord avec du matériel d\'étude personnalisé.',
  'hero.personalizedStudyPlan': 'Nous utiliserons l\'IA pour vous fournir un plan d\'étude personnalisé.',
  'hero.welcomeToP': 'Bienvenue chez P+ !',
  'hero.learnYourWayAround': 'Prenez une minute pour vous familiariser avec le site, ou reprenez-le une autre fois via les paramètres de votre compte.',
  'hero.weveCreatedADashboard': 'Nous avons créé un tableau de bord pour votre cours ici.',
  'hero.youCanAddYourPearsonCourse': 'Vous pouvez également ajouter vos propres cours non-Pearson et tester gratuitement le matériel d\'étude.',
  'hero.getExamreadyWithVideoLessons': 'Préparez-vous à l\'examen avec des leçons vidéo et des exercices spécialement conçus pour votre cours.',
  'hero.youreTexbook': 'Votre eTexbook et tous ses outils se trouvent ici.',
  'hero.VideosAndPractice': 'Il y a toujours plus de vidéos et de questions pratiques disponibles dans la section Étude et préparation aux examens.',
  'hero.pleaseSelectTheChapters': 'Veuillez sélectionner les chapitres couverts par cet examen :',
  'hero.doYouWantToAddACourse': 'Voulez-vous ajouter un cours ?',
  'hero.whatsAvailableForYou': 'Ce qui est disponible pour vous',
  'hero.whatsAvailableForYouInPearson': 'Ce que vous propose Pearson+',
  'hero.startStudying': 'Commencer l’étude',
  'hero.tableOfContents': 'Sommaire',
  'hero.search': 'Rechercher',
  'hero.notes': 'Notes et contenus surlignés',
  'hero.mondly.startLearningCTA': 'Commencer à apprendre',
  'hero.unlockYourStudyExamPrep': 'Déverrouillez votre Pack d\'étude et de préparation à l\'examen pour d\'autres cours dès à présent !',
  'hero.getExamreadyForYou': 'Préparez-vous à l’examen pour vos autres cours en utilisant Étude et préparation aux examens.',
  'hero.weVideoLessons': 'Nous avons des leçons vidéo et des exercices adaptés à vos besoins.',
  'hero.tryForFree': 'Essayez gratuitement',
  'hero.addCourseUsingStudyExamPrep': 'Ajouter un cours via Étude et préparation aux examens',
  'hero.heroBook.sectionSubHeadingV1': 'Décomposez les sujets difficiles avec des questions pratiques et des explications vidéo adaptées à votre cours.',
  'hero.for': 'Pour',
  'header.menu.moreAtPearson': 'Plus chez Pearson',
  'hero.studyExamPrepPackForAllCourse': 'Pack d\'étude et de préparation aux examens pour tous vos cours',
  'hero.myETextbooks': 'Mes eTextbooks',
  'header.continueInTheApp': 'Continuer sur l’application',
  'hero.goToMyLabMastering': 'Aller à MyLab/Mastering',
  'hero.learnTxt': 'Apprendre',
  'hero.aITutorTxt': 'Tuteur IA',
  'hero.exploreTxt': 'Explorer',
  'hero.studyExamPrepAllYourCourses': 'Étude et préparation aux examens pour tous vos cours',
  'hero.examSchedules': 'Programme d\'examen',
  'hero.examSchedules2': 'Programme d’examen',
  'hero.uploadYourSyllabus': 'Téléchargez votre programme',
  'hero.addAnExam': 'Ajouter un examen',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Téléchargez votre programme d\'études pour tirer le meilleur parti de votre Pack d\'étude et de préparation aux examens',
  'hero.UploadYourSyllabus.studyExamPrep': 'Téléchargez votre programme d\'études pour tirer le meilleur parti de votre Pack Étude et préparation aux examens',
  'hero.getRecommendationsOnWhat': 'Obtenez des recommandations sur ce quoi et quand étudier',
  'hero.noSyllabusSharingExamSchedule': 'Pas de programme ? Partager votre programme d\'examen fonctionne aussi',
  'hero.selectDropSyllabusFile': 'Sélectionnez ou déposez un fichier de programme au format PDF ou DOCX',
  'hero.uploadTxt': 'Télécharger',
  'hero.examName': 'Nom de l’examen',
  'hero.completeTxt': 'Terminer',
  'hero.exactTopicsChapter': 'Vous pourrez préciser les sujets exacts pour chaque chapitre plus tard.',
  'hero.uploadingDocument': 'Téléchargement du document',
  'hero.activatingAIComponents': 'Activation des composants IA',
  'hero.extractingClassSchoolData': 'Extraction des données relatives à la classe et à l\'école',
  'hero.extractingTextbookChapters': 'Extraction des chapitres du manuel scolaire',
  'hero.insideStudyExamPrepPack': 'Contenu du pack d\'étude et de préparation aux examens',
  'hero.userSubsGuidence': 'Vous avez un accès illimité aux leçons vidéo, aux plans d\'étude et aux exercices adaptés à votre calendrier d\'examen. Vous pouvez également poser des questions et obtenir des commentaires de la part d\'experts des matières correspondantes.',
  'hero.examPrepTxt': 'Préparation à l\'examen',
  'hero.extractingDataFromSyllabus': 'Extraction des données du programme...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'hero.insideStudyExamPrep': 'Inside Study & Exam Prep',
  'hero.testVideoLessons': 'Test out video lessons, study plans, and practice sets tailored to your exam schedule.',
  'header.qrCode.subHeaderTxt': 'Use this QR code to quickly and securely sign in to the Pearson+ app on your mobile device.',
  'header.qrCode.dontHaveApp': 'Don\'t have the app?',
  'header.qrCode.footerTxt': 'Scanning this code will redirect you to the App Store or Google Play. Once the app is downloaded, sign in by scanning it again.',
  'header.qrCode.errorMsg': 'The QR code didn\'t load. Close and try again.',
  'hero.yourETextbook': 'Your eTextbook',
  'onboard.addCourseWithEtextBundle': 'Add course using Study & Exam Prep Pack',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!'
};

export default fr;
