/* eslint-disable jsx-a11y/anchor-is-valid */
/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2021 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * QRCodeButton component
 *
 * @file QRCodeButton.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles, Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import * as constants from '../../constants';
import QRCodeIcon from '../../icons/QRCodeIcon';
import QRCodeStyles from './styles/QRCodeStyles';

const QRCodeButton = ({
  classes, title, onQrCodeClick
}) => {
  const { QR_CODE_EVENT } = constants;
  const buttonWithIcon = (
    <Button
      classes={{ root: classes.qrButtonContainerIconOnly, label: classes.iconTextContainerIconOnly }}
    >
      <QRCodeIcon />
      <div className={classes.qrBtnTitleOnlyIconOnly}>{title}</div>
    </Button>
  );

  return (
    <div>
      <div className={`${classes.modalContainer} ${classes.iconWithText}`}>
        <Button
          classes={{ root: classes.qrButtonContainer, label: classes.iconTextContainer }}
          onClick={() => onQrCodeClick(QR_CODE_EVENT.EREADER_HEADER_FIELD)}
        >
          <QRCodeIcon />
          <div className={classes.qrBtnTitle}>{title}</div>
        </Button>
      </div>
      <div className={`${classes.modalContainer} ${classes.iconOnly}`}>
        <Tooltip
          title={buttonWithIcon}
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow, popper: classes.popper }}
          arrow
        >
          <Button
            classes={{ root: classes.qrButtonContainer, label: classes.iconTextContainer }}
            onClick={() => onQrCodeClick(QR_CODE_EVENT.EREADER_HEADER_FIELD)}
          >
            <QRCodeIcon />
            <div className={classes.qrBtnTitle}>{title}</div>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

QRCodeButton.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onQrCodeClick: PropTypes.func.isRequired
};

export default withStyles(QRCodeStyles)(injectIntl(QRCodeButton));
