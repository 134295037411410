/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Bookshelf service
 *
 * @file BookshelfService.js
 * @author Infant Diana Sebastian
 */

import Framework, { BaseService } from '@greenville/framework';
import {
  BOOKSHELF_DATA_REQUESTED,
  LASTLOCATION_DATA_REQUESTED,
  INTEGRATED_SMS,
  INTEGRATED_RUMBA,
  CG_RENEWALS
} from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class BookshelfService extends BaseService {
  constructor() {
    super(BOOKSHELF_DATA_REQUESTED, 'bookshelfStatus');
  }

  handleEvent() {
    return this.getClient('marin').get('/bookshelf');
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    const bookIds = [];
    const integratedBMC = [INTEGRATED_SMS, INTEGRATED_RUMBA, CG_RENEWALS];
    const booksFormed = [];
    const bookshelfData = {
      originalBooks: response.data,
      books: booksFormed
    };

    bookshelfData.originalBooks && bookshelfData.originalBooks.length > 0 && bookshelfData.originalBooks.map(book => {
      bookIds.push(integratedBMC.includes(book.product_entitlements.business_model_code) ? book.book_id : book.product_id);
      if (book.courses.length > 0) {
        book.courses.sort((a, b) => a.course_name && b.course_name && a.course_name.localeCompare(b.course_name));
        book.courses.forEach((course) => {
          booksFormed.push({
            ...book,
            courseName: course.course_name,
            title: book.book_title || course.course_name,
            book_title: course.course_name || book.book_title,
            course_id: course.course_id || book.book_id,
            course_expired: book.product_entitlements.end_date < CommonUtils.getLocalIsoTime(),
            expired:  book.product_entitlements.end_date < CommonUtils.getLocalIsoTime() ,
            courses: [course],
            hasMultipleCourse: book.courses.length > 1,
            key: course.course_id || book.book_id,
            showAudio: CommonUtils.isAudioBook(book.audio_book_supported),
            channel_id: book.channel_id || null,
            channel_name: book.channel_name || null,
            isArchived: CommonUtils.isArchivedBook(book.collections)
          })
        })
      }
      else {
        booksFormed.push({
          ...book,
          title: book.book_title,
          course_expired: false,
          expired: book.product_entitlements.end_date < CommonUtils.getLocalIsoTime(),
          key: book.book_id,
          showAudio: CommonUtils.isAudioBook(book.audio_book_supported),
          channel_id: book.channel_id || null,
          channel_name: book.channel_name || null,
          isArchived: CommonUtils.isArchivedBook(book.collections)
        });
      }
    }
    );

    Framework.getEventManager().publish(LASTLOCATION_DATA_REQUESTED, { bookIds, bookshelfData });
  }
}
