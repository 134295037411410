/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */
/**
 * Footer styles
 *
 * @file Footerstyles.jsx
 * @author Poornima N
 * @since 0.0.1
 */
const FooterStyles = () => ({
  footerContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    bottom: 0,
    zIndex: '3',
    width: '100%',
    minHeight: '293px',
    background: '#F7F9FD',

    '@media screen and (min-width: 833px)': {
      minHeight: '190.91px'
    },
    '@media screen and (min-width: 1194px)': {
      minHeight: '132px'
    }
  },
  footercontainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '30px',
    width: '100%',
    height: '100%',
    padding: '20px 16px',

    '@media screen and (min-width: 1195px)': {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '20px 32px'
    },
    '@media screen and (min-width: 1440px)': {
      padding: '20px 72px'
    }
  },
  noDownloadAppContainer: {
    '& $footerList': {
      justifyContent: 'flex-start !important'
    }
  },
  listDiv: {
    flexGrow: 1
  },
  footerList: {
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '30px',

    '@media screen and (min-width: 1195px)': {
      justifyContent: 'center',
      gap: 20
    }
  },
  listItemStyle: {
    padding: 0,
    cursor: 'pointer',
    width: 'auto'
  },
  footerapp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  footersection: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Hind'
  },
  footercopyrightsection: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Hind',
    whiteSpace: 'nowrap'
  },
  footerlinkApp: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '8px',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

export default FooterStyles;
