/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * QR code popup style
 *
 * @file QRCodeStyles.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import mixins from '../../../../assets/styles/mixins.scss';
const QRCodeStyles = () => ({
    modalContainer: {
        width: 194,
        height: 36,
        marginRight: '14.4px',
        '@media screen and (max-width: 1024px)': {
            width: 40
        }
    },
    qrButtonContainer: {
        width: 194,
        height: 36,
        display: 'flex',
        border: `1.8px solid ${mixins.modalBorder}`,
        borderRadius: '43.2px',
        padding: '7.2px 0px 7.2px 0px',
        outline: 0,
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: mixins.darkBgBorder
        },
        '&:hover > span > svg': {
            color: mixins.whiteFont
        },
        '&:hover > span > div': {
            color: mixins.whiteFont
        },
        '@media screen and (max-width: 1024px)': {
            minWidth: 53,
            width: 40
        }
    },
    qrBtnTitle: {
        height: 29,
        fontFamily: mixins.fontFamilyTT,
        fontWeight: mixins.fontWeight700,
        fontSize: mixins.fontSize17,
        lineHeight: '28.8px',
        letterSpacing: '0.36px',
        alignItems: 'center',
        color: mixins.darkThemeColor,
        '@media screen and (max-width: 1024px)': {
            display: 'none'
        }
    },
    iconTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 29,
        gap: 6,
        minWidth: 0,
        '& svg': {
            height: 18,
            width: 18,
            color: mixins.darkThemeColor,
            '@media screen and (max-width: 1024px)': {
                width: 24,
                height: 'auto'
            }
        }
    },
    tooltip: {
        fontFamily: mixins.fontFamilyTT,
        backgroundColor: mixins.greyColor,
        color: mixins.darkThemeColor,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: mixins.fontSize14,
        lineHeight: mixins.fontSize24,
        width: 'max-content !important'
    },
    tooltipArrow: {
        color: mixins.modalBorder
    },
    popper: {
        '&[x-out-of-boundaries]': {
            display: 'none'
        }
    },
    iconWithText: {
        display: 'block',
        '@media screen and (max-width: 1024px)': {
            display: 'none'
        }
    },
    iconOnly: {
        display: 'none',
        '@media screen and (max-width: 1024px)': {
            display: 'block'
        }
    },
    qrBtnTitleOnlyIconOnly: {
        height: 24,
        fontFamily: mixins.fontFamilyTT,
        fontWeight: mixins.fontWeight700,
        fontSize: mixins.fontSize14,
        lineHeight: mixins.lineHeight24,
        alignItems: 'center'
    },
    qrButtonContainerIconOnly: {
        display: 'flex',
        outline: 0,
        gap: 8,
        '&:hover': {
            backgroundColor: 'rgba(247, 249, 253, 0.8)'
        },
        '&:hover > span > svg': {
            color: mixins.dialogInfoSubheaderColor
        },
        '&:hover > span > div': {
            color: mixins.dialogInfoSubheaderColor
        }
    },
    iconTextContainerIconOnly: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 6,
        '& svg': {
            height: 24,
            width: 24
        }
    }
});
export default QRCodeStyles;
